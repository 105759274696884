.varsCareLight() {
    --weui-BG-0: #ededed;
    --weui-BG-1: #f7f7f7;
    --weui-BG-2: #fff;
    --weui-BG-3: #f7f7f7;
    --weui-BG-4: #4c4c4c;
    --weui-BG-5: #fff;
    --weui-FG-0: #000;
    --weui-FG-HALF: #000;
    --weui-FG-1: rgba(0, 0, 0, 0.6);
    --weui-FG-2: rgba(0, 0, 0, 0.42);
    --weui-FG-3: rgba(0, 0, 0, 0.1);
    --weui-FG-4: rgba(0, 0, 0, 0.15);
    --weui-RED: #dc3636;
    --weui-ORANGE: #e17719;
    --weui-YELLOW: #bb8e00;
    --weui-GREEN: #4f8400;
    --weui-LIGHTGREEN: #2e8800;
    --weui-BRAND: #018942;
    --weui-BLUE: #007dbb;
    --weui-INDIGO: #0075e2;
    --weui-PURPLE: #6265f1;
    --weui-WHITE: #fff;
    --weui-LINK: #576b95;
    --weui-TEXTGREEN: #06ae56;
    --weui-FG: #000;
    --weui-BG: #fff;
    --weui-TAG-TEXT-ORANGE: #e17719;
    --weui-TAG-BACKGROUND-ORANGE: rgba(225, 119, 25, 0.1);
    --weui-TAG-TEXT-GREEN: #06ae56;
    --weui-TAG-BACKGROUND-GREEN: rgba(6, 174, 86, 0.1);
    --weui-TAG-TEXT-BLUE: #007dbb;
    --weui-TAG-BACKGROUND-BLUE: rgba(0, 125, 187, 0.1);
    --weui-TAG-TEXT-BLACK: rgba(0, 0, 0, 0.5);
    --weui-TAG-BACKGROUND-BLACK: rgba(0, 0, 0, 0.05);
}
